<template>
  <v-dialog @click:outside="saveDateTime" v-model="open" :persistent="persistent" width="350px" :fullscreen="fullscreen">
    <v-card>
      <v-toolbar height="36" color="primary" dark flat>
        <v-tabs v-model="tab" grow height="36">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker v-model="date" class="rounded-0" @input="tab = 'time'" full-width> </v-date-picker>
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            :key="tab"
            v-model="time"
            format="ampm"
            class="rounded-0"
            full-width
          >
          </v-time-picker>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="py-5">
       <v-spacer></v-spacer>
        <v-btn outlined color="primary" @click="$emit('close')" class="mr-2">
            Cancel
        </v-btn>
        <v-btn color="primary" @click="saveDateTime">
            OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    props: {
        fullscreen: { type: Boolean, default: false },
        persistent: { type: Boolean, default: false },
        open: { type: Boolean, default: false },
        dateFormat: { type: String, default: 'DD-MM-YYYY' },
        timeFormat: { type: String, default: 'HH:mm A' },
    },
    emits: ['close', 'save'],
    data: (vm) => ({
        date: vm.$helpers.getMomentDatas('YYYY-MM-DD'),
        time: vm.$helpers.getMomentDatas('HH:MM'),
        tab: 'date',
    }),
    methods: {
        saveDateTime() {
            const format = (`${this.date ? this.dateFormat : '' } ${this.time ? this.timeFormat : ''}`).trim(),
                dateTime = (`${this.date ? this.date : '' } ${this.time ? this.time : ''}`).trim();
            this.$emit('save', this.$helpers.getMomentDatas(format, dateTime));
            this.tab = 'date';
        }
    }
}
</script>

<style>

</style>