<template>
  <v-combobox v-model="model" ref="recipients" :filter="filter" :hide-no-data="!search" :items="items" :search-input.sync="search" @focus="toLabel = ''"
    @blur="toLabel = 'Enter Your Recipients'" hide-details="auto" multiple small-chips outlined :label="model.length == 0 ? toLabel : ''"
    @change="updateRecipients" :error-messages="error" required>
      <template v-slot:no-data>
          <v-list-item>
              <span class="subheading mr-3">Select</span>
              <v-chip color="grey lighten-3" label small>
                  {{ search }}
              </v-chip>
          </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
          <v-chip v-if="item === Object(item)" v-bind="attrs" color="grey lighten-3" 
          :input-value="selected" label small>
              <span class="pr-2"> {{ item.text }} </span>
              <v-icon small @click="parent.selectItem(item)"> $delete</v-icon>
          </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
          <v-text-field v-if="editing === item" v-model="editing.text" autofocus flat
          background-color="transparent" hide-details solo @keyup.enter="edit(index, item)"></v-text-field>
          <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
              {{ item.text }}
          </v-chip>
          <v-spacer></v-spacer>
          <v-list-item-action @click.stop>
              <v-btn icon @click.stop.prevent="edit(index, item)">
                  <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
              </v-btn>
          </v-list-item-action>
      </template>
  </v-combobox>
</template>

<script>
export default {
  props: ['error'],
  emits: ['updateTo'],
  data: (vm) => ({
    model: [],
    x: 0,
    search: null,
    y: 0,
    editing: null,
    editingIndex: -1,
    items: [{ header: 'Enter Your Recipients' }],
    toLabel: 'Enter Your Recipients'
  }),
  watch: {
    model (val, prev) {
      if (val.length === prev.length) return;
      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = { text: v, value: v.toLowerCase()}
          this.items.push(v)
          this.nonce++
        }
        return v
      })
    },
  },
  methods: {
    edit (index, item) {
      if (!this.editing) {
        this.editing = item
        this.editingIndex = index
      } else {
        this.editing = null
        this.editingIndex = -1
      }
    },
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    updateRecipients() {
      const toRecp = this.model.map(item => {
        return typeof item === 'string' ? item.toLowerCase() : item.value;
      });
      this.$emit('updateTo', toRecp);
    }
  }
}
</script>

<style>

</style>