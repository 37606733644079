<template>
  <v-row id="communication-tab-page">
    <v-col cols="12" class="py-0">
        <v-container>
            <v-form lazy-validation @submit.prevent="sendMail">
                <v-row class="ma-0 pa-0">
                    <v-col cols="12">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">To</v-subheader>
                        <toMail @updateTo="model.recipients = $event" :error="$helpers.errorMsg('recipients', $v.model.recipients, 'Recipients')"/>
                    </v-col>
                    <v-col cols="12">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Subject</v-subheader>
                        <v-text-field ref="subject" outlined v-model="model.subject" placeholder="Enter Your Subject"
                            hide-details="auto" required :error-messages="$helpers.errorMsg('name', $v.model.subject, 'Subject')"/>
                    </v-col>
                    <v-col cols="12">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Template</v-subheader>
                        <v-select  v-model="model.template" :items="tempList" outlined label="" placeholder="Choose Template"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true}"
                            hide-details="auto" clearable no-data-text="Template Not Available!.">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Message</v-subheader>
                        <div class="send-mail" :class="{'error-border': $helpers.errorMsg('name', $v.model.message, 'Message').length}">
                            <quill-editor v-model="model.message" ref="message" :options="editorOption"/>
                        </div>
                        <div v-if="$helpers.errorMsg('name', $v.model.message, 'Message').length" class="error-message mt-2 ml-2">
                            Message is required
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-row class="ma-0 mb-3" align="center" justify="space-between">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Attachments</v-subheader>
                            <v-row class="ma-0 ml-5" align="center" justify="start" v-if="attached.length">
                                <v-alert outlined shaped type="success" color="success" dense class="ma-0">
                                    {{ attached.length }} File Attached!.
                                </v-alert>
                            </v-row>
                            <v-btn fab small :color="color" @click="addAttachment" class="text-white">
                                <v-icon dark>{{ `mdi-${toggleSelect ? 'minus' : 'plus'}` }}</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="mt-6" align="center" justify="end">
                    <v-btn type="submit" :color="color"  class="mr-4 text-white" :loading="loading" :disabled="loading">
                        Send Mail  <v-icon right>mdi-email-arrow-right-outline</v-icon>
                    </v-btn>
                </v-row>
            </v-form>
        </v-container>
    </v-col>
  </v-row>
</template>

<script>
import { required } from "vuelidate/lib/validators";

import toMail from "./toMail.vue";
import { EDITOR_OPTIONS } from '@/constants/editor-options';

export default {
    components: { toMail },
    props: {
        attached: { type: Array, default: [] },
        tempList: { type: Array, default: [] },
        loading: { type: Boolean, default: false },
        el: { type: String, default: null },
        color: {type: String, default: 'primary'}
    },
    emits: [ 'toogleAdd', 'submit'],
    data: (vm) => ({
        model: { recipients: [], subject: '', message: '', attachments:[] },
        editorOption: EDITOR_OPTIONS('Your Message'),
        toggleSelect: false
    }),
    validations: {
        model: {
            recipients: { required },
            subject: { required },
            message: { required },
        },
    },
    methods: {
        addAttachment() {
            this.toggleSelect = !this.toggleSelect;
            this.$emit('toogleAdd', this.toggleSelect);
            if (this.toggleSelect && this.el) this.scrollElement();
        },
        scrollElement() {
            const element = document.getElementById(this.el);
            if(element) element.scrollIntoView();
        },
        sendMail() {
            this.model.attachments = this.attached;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$helpers.focusErrorElement(this.$v.model, this.$refs);
                return;
            }
            this.$emit('submit', this.model);
        }
    }
}
</script>

<style>

</style>